@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.3px;
}

html,
body {
  overflow-x: hidden;
  background-color: #161719;
}

html::-webkit-scrollbar {
  display: none;
}

nav {
  display: flex;
  justify-content: center;
  position: fixed !important;
  align-items: center;
  height: 65px;
  backdrop-filter: blur(10px);
  background-color: #1f20207d;
  width: 100%;
  font-size: 15px;
  z-index: 20;
  padding-top: 8px;
  padding-bottom: 8px;
}

nav .contendor {
  max-width: 1100px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

nav .sobre_mi {
  width: 21%;
  min-width: 265.5px;
  display: flex;
  justify-content: space-between;
}

nav .mi_foto {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 3px;
}

nav .mi_foto img {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  object-fit: cover;
}

nav .contendor_nombre_web {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 170px;
  font-size: 17px;
  padding-left: 6px;
  color: var(--letras-header);
}

nav .apartados {
  display: flex;
  max-width: 450px;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  min-width: 425px;
  font-size: 17px;
}

nav .apartados>div,
nav .apartados>div a {
  transition: color 0.15s, text-shadow 0.15s;
}

nav .apartados>div:hover,
nav .apartados>div:active {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.624);
}

nav .apartados>div:hover a,
nav .apartados>div:active a {
  color: rgba(255, 255, 255, 0.79);
}

nav .apartados a {
  color: var(--letras-header);
  text-decoration: none;
}

nav .nombre {
  white-space: nowrap;
}

nav .web a {
  font-size: 14px;
  text-decoration: none;
  color: var(--letras-header);
}

.hamburguesa {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburguesa svg {
  width: 80% !important;
  height: 80% !important;
  cursor: pointer;
  stroke: var(--letras-header);
  transition: stroke 0.4s;
}

.hamburguesa svg:hover,
.hamburguesa svg:active {
  stroke: #fff;
}

#portada {
  padding-top: 90px;
  background-color: #1c1d1e;
  height: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.titulo_portada {
  font-size: 45px;
  height: 120px;
  display: flex;
  justify-content: center;
}

.nombre_completo_portada {
  height: 115px;
  display: flex;
  flex-direction: column;
  font-size: 50px;
  margin: 10px 0 10px;
}

.nombre_completo_portada>.apellidos,
.nombre_completo_portada>.nombre {
  display: flex;
  justify-content: center;
  height: 53px;
}

.nombre_completo_portada>.apellidos>div:first-child {
  margin: 0 18px;
}

.num {
  margin: 30px 0 25px;
  display: flex;
  justify-content: center;
  color: var(--letras-header);
  font-size: 24px;
  text-decoration: underline;
  text-decoration-color: #1c1d1e;
  cursor: pointer;
  transition: color 0.3s, text-decoration-color 0.3s;
}

.num:hover,
.num:active {
  color: white;
  text-decoration-color: white;
}

.num_tel {
  margin-left: 10px;
}

.mis_enlaces {
  display: flex;
  justify-content: center;
}

.enlaces {
  display: flex;
  justify-content: space-between;
}

.descargar_cv {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 25px 0;
}

.descargar svg {
  cursor: pointer;
  stroke: var(--letras-header);
  transition: stroke 0.4s;
  height: 25px;
  width: 30px;
  margin-right: 8px;
  fill: var(--letras-header);
  transition: fill 0.3s;
}

.descargar {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  background-color: #333;
  font-size: 16px;
  transition: background-color 0.3s, border-color 0.3s;
  width: fit-content;
}

.descargar:hover,
.descargar:active {
  background-color: #444444a5;
  border-color: #ccc;
}

.descargar:hover svg,
.descargar:active svg {
  fill: white;
}

.enlaces div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2px;
  background-color: #12589e;
  margin: 0 15px;
  border-radius: 15px;
  width: 130px;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  height: 44px;
  transition: background-color 0.3s;
}

.enlaces a {
  color: white;
  text-decoration: none;
}

.enlaces div:hover,
.enlaces div:active {
  background-color: #0d457d;
  cursor: pointer;
}

.flecha {
  width: 17px;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.enlaces div svg,
.enlaces div svg {
  transition: transform 0.3s;
  transform: translateX(-1px);
}

.enlaces div:hover svg,
.enlaces div:active svg {
  transform: translateX(3px);
}

.logos_portada {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}

.fila1 {
  margin-top: 15px;
}

.fila1,
.fila2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fila2 {
  margin-top: 8px;
}

.fila1 img,
.fila2 img {
  max-height: 37px;
  max-width: 37px;
  margin: 0 8px;
  filter: opacity(0.85);
  filter: brightness(0.85);
  transition: filter 0.3s ease;
}

.fila1 img:hover,
.fila2 img:hover {
  cursor: pointer;
  filter: opacity(1);
  filter: brightness(1.1);
}

.fila2 img {
  margin: 0 8px;
}

.fila1 img.especial,
.fila2 img.especial {
  max-height: 32px;
  max-width: 32px;
}

.entre_filas {
  filter: opacity(0.4);
  margin-top: 10px;
  height: 1px;
  width: 350px;
  border-radius: 4px;
  background-color: var(--letras-header);
}

.separador_proyectos {
  height: 1px;
  width: 100%;
  background-color: #373839;
  margin-bottom: 60px;
  margin-top: 60px;
}

.titulo_proyectos {
  width: 100%;
  height: fit-content;
  color: #fff;
}

.titulo_proyecto {
  color: #fff;
  font-size: 18px;
  height: 40px;
  text-decoration: underline;
}

.proyectos {
  height: fit-content;
  width: 100%;
}

.proyecto {
  display: flex;
  height: 310px;
  margin-top: 20px;
  overflow-y: hidden;
}

.proyecto_reves {
  flex-direction: row-reverse;
}

.proyecto>div {
  height: 100%;
  width: 528px;
}

.todo_descripcion {
  display: flex;
  flex-direction: column;
}

.parrafo_proyecto {
  margin: 10px 0;
  height: fit-content;
  color: var(--letras-header);
}

.parrafo_proyecto.parrafo2 {
  margin-top: 0;
}

.proyecto_reves .todo_descripcion {
  width: 523px;
}

.botones_proyecto {
  display: flex;
  margin-top: 10px;
}

.botones_proyecto a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  width: 100px;
  padding: 8px 0;
  border-radius: 12px;
  background-color: #272829;
  font-size: 14px;
  cursor: pointer;
  border: 0.5px solid #373839;
  text-decoration: none;
  color: #c7c7c7;
  stroke: var(--letras-header);
  transition: color 0.3s ease-in, background-color 0.3s ease-in, border 0.3s ease-in, stroke 0.3s ease-in;
}

.botones_proyecto a:hover,
.botones_proyecto a:active {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  width: 100px;
  padding: 8px 0;
  border-radius: 12px;
  background-color: #383839;
  font-size: 14px;
  cursor: pointer;
  border: 0.5px solid #848383;
  text-decoration: none;
  color: #e9e9e9;
  stroke: #edeaea;
}

.botones_proyecto a svg,
.botones_proyecto a .fa-github {
  transition: color 0.3s ease-in, stroke 0.3s ease-in, filter 0.3s ease-in;
}

.botones_proyecto a:hover svg,
.botones_proyecto a:active svg,
.botones_proyecto a:hover .fa-github,
.botones_proyecto a:active .fa-github {
  stroke: #ffffff;
  color: white;
  filter: opacity(1) brightness(1.1);
}

.boton_margen {
  margin-left: 15px;
}

.botones_proyecto a svg {
  stroke: var(--letras-header);
}

.logo_github_botones_proyecto {
  height: 17px;
  width: 17px;
}

.tecnologias,
.tecnologias1,
.tecnologias2 {
  display: flex;
  gap: 25px;
  font-size: 11px;
  margin: 25px 0 0;
  background-color: #1c1d1d;
  border-radius: 30px;
  width: fit-content;
  padding: 0 20px;
  border: 1px solid #28292a;
  height: fit-content;
  font-size: 14px;
}

.tecnologias_pequeño {
  display: flex;
  flex-direction: column;
  display: none;
}

.tecnologias p,
.tecnologias1 p,
.tecnologias2 p {
  padding: 5px;
  color: var(--letras-header);
}

.tecnologias2,
.tecnologias1 {
  max-width: 260px;
  padding: 0 13px;
}

.tecnologias2 p,
.tecnologias1 p {
  padding: 5px 0;
}

.todo_img_proyecto {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 54px;
}

.proyecto_reves .todo_img_proyecto {
  margin-right: 54px;
  margin-left: 0;
}

.proyecto_reves .todo_img_proyecto {
  justify-content: start;
}

.img_proyecto {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: #28292a;
  padding: 5px;
  transition: background-color 0.4s;
}

.img_proyecto:hover,
.img_proyecto:active {
  background-color: #363738;
}

.img_proyecto img {
  height: 100%;
  width: 100%;
  overflow-clip-margin: content-box;
  overflow: clip;
  border-radius: 5px;
}

.todo_proyectos,
.todo_sobre_mi,
.todo_certificaciones,
.todo_skills,
.footer,
.todo_idiomas {
  display: flex;
  justify-content: center;
  background-color: #161719;
  padding-bottom: 30px;
}

.todo_idiomas {
  padding-top: 15px;
}

.todo_proyectos {
  padding-top: 30px;
}

#proyectos,
#sobre_mi,
#certificaciones,
#skills,
.todo_contacto,
.redes,
#idiomas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1100px;
}

.titulo_proyectos,
.titulo_sobre_mi,
.nombre_sobre_mi,
.titulo_certificaciones,
.titulo_idiomas,
.titulo_skills {
  width: 100%;
}

.titulo_proyectos h3,
.titulo_sobre_mi h3,
.titulo_certificaciones h3,
.titulo_skills h3,
.titulo_idiomas h3 {
  font-size: 18px;
  color: #5a8bd5;
  font-family: Poppins, sans-serif;
}

.titulo_sobre_mi {
  margin: 35px 0 20px;
}

.nombre_sobre_mi {
  color: #fff;
  font-size: 1.75em;
  margin-bottom: 5px;
}

.acerca_de_mi {
  display: flex;
}

.descripcion,
.titulacion {
  height: 230px;
  width: 520px;
}

.titu {
  background-color: #dbe0e409;
  border: 1px solid #373839;
  padding: 10px 20px;
  border-radius: 10px;
  height: fit-content;
  margin-top: 5px;
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
}

.descripcion {
  margin-right: 60px;
}

.parrafo {
  margin: 15px 0;
  height: fit-content;
  color: var(--letras-header);
}

.titulo_titulacion {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.graduado {
  color: var(--letras-header);
}

.fecha_graduado {
  color: var(--letras-header);
  font-size: 13px;
  margin-top: 5px;
}

.titulo_certificaciones,
.titulo_idiomas {
  margin-bottom: 17px;
}

.certificaciones {
  height: 161px;
  width: 1100px;
  display: flex;
  justify-content: space-between;
}

.mis_idiomas {
  height: 80px;
  width: 1100px;
  display: flex;
  justify-content: space-between;
}

.certificacion,
.skill {
  background-color: #dbe0e409;
  border: 1px solid #373839;
  padding: 25px 20px;
  border-radius: 10px;
  height: fit-content;
  margin-top: 5px;
  width: 540px;
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
}

.titu:hover,
.titu:active,
.certificacion:hover,
.certificacion:active,
.skill:hover,
.skill:active {
  border-color: #737575;
  background-color: #dbe0e413;
  cursor: pointer;
}

.titu:hover,
.titu:active {
  cursor: default;
}

.skill:hover,
.skill:active {
  cursor: default;
}

.titulo_certificacion,
.titulo_skill,
.nombre_idioma,
.nivel {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.nombre_idioma,
.nivel {
  margin-bottom: 0px;
}

.certificado {
  color: var(--letras-header);
}

.fecha_graduado {
  color: var(--letras-header);
  font-size: 13px;
  margin-top: 5px;
}

.titulo_skills {
  margin: 35px 0 20px;
}

.titulo_tecnologias {
  margin-top: 20px;
}

.skills {
  width: 1100px;
  display: flex;
  justify-content: space-between;
}

.skill {
  padding: 15px 20px;
  width: 260px;
  display: flex;
  justify-content: center;
  height: 86px;
  margin-bottom: 15px;
  margin-right: 20px;
}

.skill.tecnologia {
  padding: 15px 20px;
  width: 250px;
  display: flex;
  justify-content: center;
  height: 60px;
  margin-bottom: 15px;
  margin-right: 32.7px;
}

.titulo_skill {
  margin-bottom: 0;
  display: flex;
  text-align: center;
  align-items: center;
}

.primera_fila_skills,
.segunda_fila_skills {
  display: flex;
  justify-content: center;
}


.idioma {
  padding: 0px 20px;
  height: 79px;
  margin-top: 5px;
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.idioma_ingles {
  padding-left: 30px;
}

.idioma_frances {
  padding-right: 30px;
}

.nivel_idioma {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 80%;
  margin-top: 10px;
}

.nombre_idioma {
  margin-top: 10px;
  text-decoration: underline;
}

.circulos_idiomas {
  height: 30px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.circulo {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #d4ab49;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.circulo_vacio {
  background-color: #444033d4;
}

.circulo_medio {
  display: flex;
  overflow: hidden;
  background-color: transparent;
}

.medio_izq,
.medio_der {
  width: 50%;
  height: 100%;
}

.medio_izq {
  background-color: #d4ab49;
}

.medio_der {
  background-color: #444033d4;
}

.nivel {
  padding-right: 4%;
  padding-top: 5px;
}

.nivel_corto {
  padding-right: 4.1%;
}

.footer {
  border-top: 1px solid #202020;
  padding-top: 35px;
  background-color: #111213;
}

.todo_contacto {
  flex-direction: row;
}

.contacto,
.redes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 540px;
}

.contacto a,
.contacto div {
  width: 55%;
  text-decoration: none;
}

.contactame {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in;
}

.contactame svg {
  transition: transform 0.2s ease-in;
}

.contactame:hover,
.contactame:active {
  transform: scale(1.02) translateY(0.3px);
}

.contactame:hover svg,
.contactame:active svg {
  transform: scale(1.02) translateY(0.1px);
}

.contactame svg {
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.developed {
  color: var(--letras-header);
  font-size: 15px;
  word-spacing: 2px;
}

.redes {
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.redes a {
  display: block;
}

.logo_linkedin,
.logo_github,
.logo_correo,
.logo_tel {
  height: 35px;
  width: 35px;
  margin: 0 25px;
}

.logo_tel {
  margin: 0 20.5px;
}

.fa-linkedin,
.fa-github,
.logo_correo .fa-envelope,
.logo_tel .fa-phone {
  color: var(--letras-header);
  height: 100%;
  width: 100%;
  filter: opacity(0.85) brightness(1);
  transition: filter 0.3s ease-in, color 0.4s ease-in, transform 0.1s ease-in, height 0.1s ease-in, width 0.1s ease-in;
}

.logo_tel .fa-phone {
  height: 87%;
  width: 87%;
}

.logo_tel .fa-phone:hover,
.fa-linkedin:hover,
.logo_github .fa-github:hover,
.logo_correo .fa-envelope:hover,
.logo_tel .fa-phone:active,
.fa-linkedin:active,
.logo_github .fa-github:active,
.logo_correo .fa-envelope:active {
  color: white;
  filter: opacity(1) brightness(1.1);
  transform: translateX(-1px) translateY(-1px);
  height: 37px;
  width: 37px;
}

.logo_tel .fa-phone:hover,
.logo_tel .fa-phone:active {
  cursor: pointer;
  height: 33px;
  width: 33px;
}

.icon-tabler-external-link {
  transition: filter 0.3s ease-in, color 0.3s ease-in;
}

.fa-github:hover,
.fa-github:active,
.icon-tabler-external-link:hover,
.icon-tabler-external-link:active {
  color: white;
  filter: opacity(1) brightness(1.1);
}
@media(max-width: 1200px) {

  .separador_proyectos {
    width: 80%;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  #proyectos {
    width: 100%;
  }

  .titulo_proyectos {
    margin-bottom: 40px;
  }

  .proyectos {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .proyecto {
    height: fit-content;
    flex-direction: column-reverse;
    width: 80%;
  }

  .proyecto_reves {
    flex-direction: column-reverse;
  }

  .proyecto>div {
    width: 100%;
    height: 100%;
  }

  .parrafo_proyecto {
    margin: 13px 0;
  }

  .todo_img_proyecto {
    justify-content: start;
    margin-bottom: 40px;
    margin-left: 0;
  }

  .img_proyecto {
    height: 100%;
    width: 100%;
    padding: 5px;
  }

  .img_proyecto img {
    height: 100%;
    width: 100%;
  }

  .todo_img_proyecto {
    display: flex;
    align-items: center;
    justify-content: end;

  }

  .proyecto_reves .todo_img_proyecto {
    margin-right: 0;
  }

  .proyecto_reves .todo_descripcion {
    width: 100%;
  }

  .titulo_proyectos,
  .titulo_sobre_mi,
  .nombre_sobre_mi,
  #certificaciones,
  #skills,
  .todo_contacto,
  #idiomas {
    width: 80%;
  }

  .titulo_sobre_mi {
    margin-top: 0;
  }

  #sobre_mi,
  .certificaciones,
  .todo_skills,
  .skills,
  .footer,
  .mis_idiomas {
    width: 100%;
  }

  .acerca_de_mi {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .descripcion {
    margin: 0;
    height: fit-content;
    margin-bottom: 10px;
  }

  .acerca_de_mi>div {
    width: 100%;
  }

  .descripcion>div {
    margin-bottom: 15px;
  }

  .titulacion {
    height: 190px;
  }

  .titu {
    height: fit-content;
    padding: 20px 20px;
  }

  .fecha_graduado {
    margin-top: 15px;
  }

  .todo_certificaciones,
  #certificaciones,
  .certificaciones,
  #idiomas,
  .mis_idiomas {
    height: fit-content;
  }

  .certificaciones {
    flex-direction: column;
  }

  .certificacion {
    width: 100%;
    margin-bottom: 15px;
  }

  .skills {
    flex-direction: column;
  }

  .primera_fila_skills>:last-child,
  .segunda_fila_skills>:last-child {
    margin-right: 0;
  }

  .skill {
    width: 50%;
  }

  .contacto {
    align-items: start;
    padding-left: 5px;
  }

  .redes {
    justify-content: end;
  }

  .contacto div.developed {
    width: 92%;
  }

  .circulos_idiomas {
    width: 90%;
  }

  .idioma_ingles {
    padding-left: 20px;
    padding-right: 50px;
  }

  .idioma_frances {
    padding-left: 50px;
    padding-right: 20px;
  }
}

@media(max-width: 1020px) {
  nav {
    position: relative;
  }

  nav .apartados {
    position: fixed;
    right: 55px;
    top: 80px;
    background-color: #202326;
    flex-direction: column;
    align-items: start;
    padding: 10px 0 10px 10px;
    width: 150px;
    min-width: 0;
    font-size: 15px;
    z-index: 10;
  }

  nav .apartados>div {
    padding: 5px 0;
  }

  .hamburguesa {
    z-index: 10;
  }

  .tapar {
    position: fixed;
    width: 100vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.263);
    z-index: 5;
  }

  #portada {
    height: 590px;
  }

  .titulo_portada {
    font-size: 38px;
    height: 100px;
  }

  .nombre_completo_portada {
    height: 105px;
    font-size: 40px;
    margin: 10px 0 0;
  }

  .nombre_completo_portada>.apellidos,
  .nombre_completo_portada>.nombre {
    height: 48px;
  }

  .num {
    margin: 25px 0 20px;
    font-size: 22px;
  }
}

@media(max-width: 860px) {
  .todo_idiomas {
    height: fit-content;
  }

  .titulo_idiomas {
    margin-bottom: 10px;
  }

  .idioma {
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: fit-content;
  }

  .nombre_idioma {
    margin-bottom: 10px;
  }

  .nivel_idioma {
    align-items: center;
  }

  .nivel {
    padding: 0;
    margin-top: 15px;
  }
}

@media(max-width: 680px) {
  #portada {
    height: 535px;
  }

  .titulo_portada {
    font-size: 30px;
    height: 85px;
  }

  .nombre_completo_portada {
    height: 95px;
    font-size: 30px;
    margin: 10px 0 0;
  }

  .nombre_completo_portada>.apellidos,
  .nombre_completo_portada>.nombre {
    height: 35px;
  }

  .nombre_completo_portada>.apellidos>div:first-child {
    margin: 0 16px;
  }

  .num {
    margin: 5px 0 20px;
    font-size: 20px;
  }

  .enlaces div {
    margin: 0 15px;
    width: 120px;
    font-size: 14px;
    height: 40px;
  }

  .logos_portada {
    margin-top: 10px;
  }

  .fila1 {
    margin-top: 12px;
  }

  .fila2 {
    margin-top: 8px;
  }

  .fila1 img,
  .fila2 img {
    max-height: 32px;
    max-width: 32px;
    margin: 0 6px;
  }

  .fila2 img {
    margin: 0 6px;
  }

  .fila1 img.especial,
  .fila2 img.especial {
    max-height: 30px;
    max-width: 30px;
  }

  .entre_filas {
    filter: opacity(0.4);
    margin-top: 8px;
    height: 1px;
    width: 300px;
  }

  .todo_contacto {
    width: 100%;
    flex-direction: column;
  }

  .contacto {
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }

  .contacto *,
  .redes {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .redes {
    margin: 0;
  }

  .tecnologias {
    display: none;
  }

  .tecnologias_pequeño {
    display: flex;
  }
}

@media(max-width: 605px) {

  .primera_fila_skills,
  .segunda_fila_skills {
    flex-direction: column;
  }

  .fila_tecnologias {
    align-items: center;
  }

  .fila_tecnologias .skill.tecnologia {
    margin-right: 0;
    width: 100%;
  }

  .skill {
    width: 100%;
    padding: 10px 15px;
  }

  .mis_idiomas {
    flex-direction: column;
    width: 100%;
  }

  .idioma {
    padding: 0;
    width: 100%;
  }

  .circulos_idiomas {
    align-items: center;
  }

  .nombre_idioma {
    margin-top: 12px;
    margin-bottom: 5px;
  }

  .idioma_ingles {
    margin-bottom: 30px;
  }

  .nivel {
    margin-top: 10px;
  }

  .nivel_idioma {
    width: 100%;
  }

  .circulo {
    height: 26px;
    width: 26px;
  }
}

@media(max-width: 550px) {
  .titulacion {
    height: fit-content;
    padding-bottom: 20px;
  }
}

@media(max-width: 500px) {
  .hamburguesa {
    width: 52.5px;
  }

  #portada {
    height: 470px;
  }

  .titulo_portada {
    font-size: 24px;
    height: 67px;
  }

  .nombre_completo_portada {
    height: 65px;
    font-size: 17px;
    margin: 5px 0 0;
  }

  .nombre_completo_portada>.apellidos,
  .nombre_completo_portada>.nombre {
    height: 26px;
  }

  .nombre_completo_portada>.apellidos>div:first-child {
    margin: 0 8px;
  }

  .num {
    margin: 3px 0 18px;
    font-size: 14px;
  }

  .enlaces div {
    margin: 0 13px;
    width: 110px;
    font-size: 13px;
    height: 34px;
  }

  .logos_portada {
    margin-top: 10px;
  }

  .fila1 {
    margin-top: 18px;
  }

  .fila2 {
    margin-top: 7px;
  }

  .fila1 img,
  .fila2 img {
    max-height: 29px;
    max-width: 29px;
    margin: 0 5px;
  }

  .fila2 img {
    margin: 0 5px;
  }

  .fila1 img.especial,
  .fila2 img.especial {
    max-height: 27px;
    max-width: 27px;
  }

  .entre_filas {
    margin-top: 5px;
    width: 275px;
  }

  .todo_contacto {
    width: 100%;
    flex-direction: column;
  }

  .contacto {
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }

  .contacto *,
  .redes {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .redes {
    margin: 0;
  }
}

@media(max-width: 385px) {
  nav .sobre_mi {
    min-width: 230px;
  }

  nav .mi_foto {
    height: 50px;
    width: 50px;
  }

  nav .mi_foto img {
    height: 65px;
    width: 65px;
  }

  nav .contendor_nombre_web {
    min-width: 170px;
    font-size: 15px;
  }

  .hamburguesa {
    width: 45px;
  }

  #portada {
    height: 445px;
    padding-top: 75px;
  }

  .titulo_portada {
    font-size: 20px;
    height: 60px;
  }

  .logos_portada {
    margin-top: 10px;
  }

  .fila2 {
    margin-top: 7px;
  }

  .fila1 img,
  .fila2 img {
    max-height: 27px;
    max-width: 27px;
    margin: 0 4px;
  }

  .fila2 img {
    margin: 0 4px;
  }

  .fila1 img.especial,
  .fila2 img.especial {
    max-height: 25px;
    max-width: 25px;
  }

  .entre_filas {
    width: 250px;
  }

  .titulacion {
    height: fit-content;
  }

  .circulos_idiomas {
    max-width: 250px;
    min-width: 240px;
  }

  .circulo {
    height: 21px;
    width: 21px;
  }

  .nombre_idioma {
    font-size: 16px;
    margin-top: 13.5px;
  }

  .nivel {
    font-size: 16px;
  }

  .logo_linkedin,
  .logo_github,
  .logo_correo,
  .logo_tel {
    height: 31px;
    width: 31px;
    margin: 0 20px;
  }

  .logo_tel {
    height: 31px;
    width: 31px;
    margin: 0 18px;
  }

  .logo_tel .fa-phone:hover,
  .fa-linkedin:hover,
  .logo_github .fa-github:hover,
  .logo_correo .fa-envelope:hover,
  .logo_tel .fa-phone:active,
  .fa-linkedin:active,
  .logo_github .fa-github:active,
  .logo_correo .fa-envelope:active {
    height: 33px;
    width: 33px;
  }

  .logo_tel .fa-phone:hover,
  .logo_tel .fa-phone:active {
    height: 29px;
    width: 29px;
  }
}

@media(max-width: 320px) {
  .contacto div.developed {
    width: 70%;
  }

  .contacto,
  .redes {
    width: fit-content;
  }
}